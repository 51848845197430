import { navigate } from "gatsby";
import React from "react"
import "../static/style/componentStyle/get-started-modal.scss"
require('react-toastify/dist/ReactToastify.css');




const BookDemo = ({ content,routeTo,target }) => {
    const handleClick = () => {
        // Perform any logic you need before navigation
        if(target==="_blank"){
            window.open(routeTo, '_blank');
            return;
        }
        navigate(routeTo);

    };
    return (
        <div className="get-started">
            {/* {!isOpen ? ( */}
            <button onClick={handleClick}>{content}</button>

        </div>
    )
}

export default BookDemo
