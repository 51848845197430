import Parser from "html-react-parser"
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import BannerHeader from '../components/bannerHeader';
import BookDemo from '../components/book-demo';
import Underline from '../components/underline';
import { featurePageData } from '../services/helperFunctions';
import "../static/style/pageStyle/features.scss"
import GetStartedModal from "../components/getStaredModal";

const Features = props => {
    const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
    const handleIsOpenModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const [assetSelect, setAssetSelect] = useState(0);
    const [qualitySelect, setQualitySelect] = useState(0);
    const [incidentSelect, setIncidentSelect] = useState(0);


    const [imageTransitionAsset, setImageTransitionAsset] = useState(false);
    const [imageTransitionQuality, setImageTransitionQuality] = useState(false);
    const [imageTransitionIncident, setImageTransitionIncident] = useState(false);

    const strapiData = useStaticQuery(
        graphql`
      {
        allPryzmFeature {
            nodes {
              pryzmFeature {
                journey {
                  attributes {
                    cmpName
                    heading
                    subheading
                    dynamicCmp {
                      iconUrl
                      cardHeading
                      content
                    }
                  }
                }
              }
            }
          }
      }
    `
    )

    const {
        banner,
        section1,
        section2,
        section3,
        section4
    } = featurePageData(strapiData);

    useEffect(() => {
        section1.dynamicCmp.forEach((item) => {
            const img = new Image();
            img.src = `${process.env.STRAPI_URL + item.iconUrl}`;
        });
    }, [section1.dynamicCmp]);

    useEffect(() => {
        section2.dynamicCmp.forEach((item) => {
            const img = new Image();
            img.src = `${process.env.STRAPI_URL + item.iconUrl}`;
        });
    }, [section2.dynamicCmp]);

    useEffect(() => {
        section3.dynamicCmp.forEach((item) => {
            const img = new Image();
            img.src = `${process.env.STRAPI_URL + item.iconUrl}`;
        });
    }, [section3.dynamicCmp]);

    const handleClick = (index, type) => {
        if (type == "asset") {
            if (index == assetSelect) {
                return;
            }
            setImageTransitionAsset(false);

            setTimeout(() => {
                setAssetSelect(index);
            }, 300);
        }
        else if (type == "quality") {
            if (index == qualitySelect) {
                return;
            }
            setImageTransitionQuality(false);
            setTimeout(() => {
                setQualitySelect(index);
            }, 300);
        }
        else if (type == "incident") {
            if (index == incidentSelect) {
                return;
            }
            setImageTransitionIncident(false);
            setTimeout(() => {
                setIncidentSelect(index);
            }, 300);
        }
    };
    useEffect(() => {
        if (assetSelect !== null) {
            const timeout = setTimeout(() => {
                setImageTransitionAsset(true); // Enable transition after a delay
            }, 100);

            return () => clearTimeout(timeout);
        }
    }, [assetSelect]);

    useEffect(() => {
        if (qualitySelect !== null) {
            const timeout = setTimeout(() => {
                setImageTransitionQuality(true); // Enable transition after a delay
            }, 100);

            return () => clearTimeout(timeout);
        }
    }, [qualitySelect]);
    useEffect(() => {
        if (qualitySelect !== null) {
            const timeout = setTimeout(() => {
                setImageTransitionIncident(true); // Enable transition after a delay
            }, 100);

            return () => clearTimeout(timeout);
        }
    }, [incidentSelect]);
    const data = [
        {
            "heading": "column-level Lineage",
            "content": "Provides granular visibility into data flow from source to consumption, enhancing traceability",
            "image": "https://static.toiimg.com/photo/75503656.cms"
        },
        {
            "heading": "column-level Lineage",
            "content": "Provides granular visibility into data flow from source to consumption, enhancing traceability",
            "image": "https://cdn.getmidnight.com/45d07b00b0188a892509950ff919e14e/2022/01/Sometime-blog-image.png"
        },
        {
            "heading": "column-level Lineage",
            "content": "Provides granular visibility into data flow from source to consumption, enhancing traceability",
            "image": "https://cdn.getmidnight.com/45d07b00b0188a892509950ff919e14e/2022/01/Sometime-blog-image.png"
        },
        {
            "heading": "column-level Lineage",
            "content": "Provides granular visibility into data flow from source to consumption, enhancing traceability",
            "image": "https://cdn.getmidnight.com/45d07b00b0188a892509950ff919e14e/2022/01/Sometime-blog-image.png"
        },
        {
            "heading": "column-level Lineage",
            "content": "Provides granular visibility into data flow from source to consumption, enhancing traceability",
            "image": "https://static.toiimg.com/photo/75503656.cms"
        }
    ];

    useEffect(() => {
        if (props?.location?.state) {
            const id = Object.values(props?.location?.state)
                .filter(
                    (value, index) =>
                        typeof value === "string" &&
                        index !== Object.keys(props?.location?.state).length - 1
                )
                .join("")
            if (id) {
                const partnersLocation = document.getElementById(id)
                if (partnersLocation) {
                    partnersLocation.scrollIntoView({ behavior: "smooth" })
                }
            }
        }
    }, [props?.location?.state])
    const metaTitle = "Our Features | PRYZM";
    const metaDescription = "Discover PRYZM's powerful modules—Asset Portal, Incident Detection & Workbench, and Data Quality—designed to ensure precise, trustworthy data.";


    return (
        <><Helmet
            htmlAttributes={{
                lang: "en",
            }}
        >
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
        </Helmet>
            <div className={`platform-v3 features`}>
                <div className='page-content'>
                    <div className='banner'>
                        <div className='page-wrapper'>
                            <div className='heading'>
                                <BannerHeader headingType={"bannerTitle"}
                                    header={banner.heading}
                                    line={"single-line"} />
                            </div>
                            <div className='para'>
                                <p>{banner.subheading}</p>
                            </div>
                        </div>
                    </div>

                    {/* asset portat  */}
                    <div className='general-feature' id="Asset">
                        <div className='page-wrapper'>
                            <div className='heading'>
                                <h3>{section1.heading}</h3>
                            </div>
                            <div className='para'>
                                <p>{section1.subheading}</p>
                            </div>
                            <div className='content'>
                                <div className='text'>
                                    {section1.dynamicCmp.map((item, index) => (
                                        <>
                                            <div
                                                key={index}
                                                className={`head-para ${assetSelect === index ? 'selected' : ''}`} onClick={() => handleClick(index, "asset")}>
                                                <h3>{item.cardHeading}</h3>
                                                {assetSelect === index ? <p >{item.content}</p> : ""}
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <div className='image'>
                                    {assetSelect !== null && <img loading="lazy"
                                        className={`image-transition ${imageTransitionAsset ? 'image-transition-show' : ''}`}
                                        src={`${process.env.STRAPI_URL + section1.dynamicCmp[assetSelect].iconUrl}`}
                                        alt={data[assetSelect].heading}
                                    />}
                                </div>
                            </div>
                            <div className='content-mobile'>
                                <div className='text'>
                                    {section1.dynamicCmp.map((item, index) => (
                                        <>
                                            <div
                                                key={index}
                                                className={`head-para ${assetSelect === index ? 'selected' : ''}`} onClick={() => handleClick(index, "asset")}>
                                                <h3>{item.cardHeading}</h3>
                                                <p>{item.content}</p>
                                                {assetSelect === index && (
                                                    <div className='image'>
                                                        {assetSelect !== null && <img loading="lazy"
                                                            className={`image-transition ${imageTransitionAsset ? 'image-transition-show' : ''}`}
                                                            src={`${process.env.STRAPI_URL + section1.dynamicCmp[assetSelect].iconUrl}`}
                                                            alt={data[assetSelect].heading}
                                                        />}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Underline />

                    {/* data quality */}
                    <div className='general-feature' id="Data">
                        <div className='page-wrapper'>
                            <div className='heading'>
                                <h3>{section2.heading}</h3>
                            </div>
                            <div className='para'>
                                <p>{section2.subheading}</p>
                            </div>
                            <div className='content'>
                                <div className='text'>
                                    {section2.dynamicCmp.map((item, index) => (
                                        <>
                                            <div
                                                key={index}
                                                className={`head-para ${qualitySelect === index ? 'selected' : ''}`} onClick={() => handleClick(index, "quality")}>
                                                <h3>{item.cardHeading}</h3>
                                                {qualitySelect === index ? <p >{item.content}</p> : ""}
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <div className='image'>
                                    {qualitySelect !== null && <img loading="lazy"
                                        className={`image-transition ${imageTransitionQuality ? 'image-transition-show' : ''}`}
                                        src={`${process.env.STRAPI_URL + section2.dynamicCmp[qualitySelect].iconUrl}`}
                                        alt={data[qualitySelect].heading}
                                    />}
                                </div>
                            </div>
                            <div className='content-mobile'>
                                <div className='text'>
                                    {section2.dynamicCmp.map((item, index) => (
                                        <>
                                            <div
                                                key={index}
                                                className={`head-para ${qualitySelect === index ? 'selected' : ''}`} onClick={() => handleClick(index, "quality")}>
                                                <h3>{item.cardHeading}</h3>
                                                <p>{item.content}</p>
                                                {qualitySelect === index && (
                                                    <div className='image'>
                                                        {qualitySelect !== null && <img loading="lazy"
                                                            className={`image-transition ${imageTransitionQuality ? 'image-transition-show' : ''}`}
                                                            src={`${process.env.STRAPI_URL + section2.dynamicCmp[qualitySelect].iconUrl}`}
                                                            alt={data[qualitySelect].heading}
                                                        />}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Underline />

                    {/* incident management */}
                    <div className='general-feature' id="Incident">
                        <div className='page-wrapper'>
                            <div className='heading'>
                                <h3>{section3.heading}</h3>
                            </div>
                            <div className='para'>
                                <p>{section3.subheading}</p>
                            </div>
                            <div className='content'>
                                <div className='text'>
                                    {section3.dynamicCmp.map((item, index) => (
                                        <>
                                            <div
                                                key={index}
                                                className={`head-para ${incidentSelect === index ? 'selected' : ''}`} onClick={() => handleClick(index, "incident")}>
                                                <h3>{item.cardHeading}</h3>
                                                {incidentSelect === index ? <p >{item.content}</p> : ""}
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <div className='image'>
                                    {incidentSelect !== null && <img loading="lazy"
                                        className={`image-transition ${imageTransitionIncident ? 'image-transition-show' : ''}`}
                                        src={`${process.env.STRAPI_URL + section3.dynamicCmp[incidentSelect].iconUrl}`}
                                        alt={data[incidentSelect].heading}
                                    />}
                                </div>
                            </div>
                            <div className='content-mobile'>
                                <div className='text'>
                                    {section3.dynamicCmp.map((item, index) => (
                                        <>
                                            <div
                                                key={index}
                                                className={`head-para ${incidentSelect === index ? 'selected' : ''}`} onClick={() => handleClick(index, "incident")}>
                                                <h3>{item.cardHeading}</h3>
                                                <p>{item.content}</p>
                                                {incidentSelect === index && (
                                                    <div className='image'>
                                                        {incidentSelect !== null && <img loading="lazy"
                                                            className={`image-transition ${imageTransitionIncident ? 'image-transition-show' : ''}`}
                                                            src={`${process.env.STRAPI_URL + section3.dynamicCmp[incidentSelect].iconUrl}`}
                                                            alt={data[incidentSelect].heading}
                                                        />}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Underline /> */}
                    <div className='CTA'>
                        <div className='page-wrapper'>
                            <h2>{Parser(section4.heading)}</h2>
                            <div className="btn-area">
                            <GetStartedModal content={"Contact Us"} isOpen={isOpen} setIsOpen={setIsOpen} isModalOpen={isModalOpen} handleIsOpenModal={handleIsOpenModal} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Features